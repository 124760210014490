import type { Plugin } from '@nuxt/types';
import { isOciCookieName } from '~/modules/oci/constants';

const moduleOptions = `{"isOci":true,"nonOciBaseUrl":"https://matindustri.foodtech.no","cookiesDefaultOpts":{"httpOnly":false,"secure":"true","sameSite":"NONE","path":"/"}}`;

const ociMiddlewarePlugin: Plugin = ({ app }) => {
  const options = JSON.parse(moduleOptions)
  if (!options.isOci) return;
  const ociCookie = !!app.$cookies.get(isOciCookieName, options.cookieDefaultOpts);
  if (!ociCookie) {
    if (window && options.nonOciBaseUrl) {
      window.location.href = options.nonOciBaseUrl;
    }
  }
}

export default ociMiddlewarePlugin;