import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5575b23d = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _5abc258a = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _04649148 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _14fc24b7 = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _39ef611d = () => interopDefault(import('../_templates/pages/wishlist/Wishlist.vue' /* webpackChunkName: "" */))
const _031fd36c = () => interopDefault(import('../modules/oci/pages/OciCart.vue' /* webpackChunkName: "" */))
const _d8439ba6 = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _4fce01bb = () => interopDefault(import('../pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _14eb416e = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _217d351c = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _7291b084 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _2032e0dc = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _7ea96db2 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _153ae201 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _6ba8b2b7 = () => interopDefault(import('../modules/my-account/pages/CompanyInformation.vue' /* webpackChunkName: "" */))
const _55dcbf48 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _287f3c0f = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _131e89aa = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _7fdb607d = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _7d1a0e3d = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _1f49f93d = () => interopDefault(import('../_templates/pages/search/Search.vue' /* webpackChunkName: "" */))
const _73914e12 = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))
const _76024347 = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _500702e8 = () => interopDefault(import('../pages/customer/MyAccount/useSidebarLinkGroups.ts' /* webpackChunkName: "pages/customer/MyAccount/useSidebarLinkGroups" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _5575b23d,
    name: "checkout",
    children: [{
      path: "konto",
      component: _5abc258a,
      name: "checkout-account"
    }, {
      path: "bestilling",
      component: _04649148,
      name: "checkout-order"
    }, {
      path: "takk",
      component: _14fc24b7,
      name: "checkout-thank-you"
    }]
  }, {
    path: "/onskeliste",
    component: _39ef611d,
    name: "wishlist"
  }, {
    path: "/oci-cart",
    component: _031fd36c,
    name: "oci-cart"
  }, {
    path: "/customer/reset-password",
    component: _d8439ba6,
    alias: "/customer/account/createPassword",
    name: "reset-password"
  }, {
    path: "/customer",
    component: _4fce01bb,
    meta: {"titleLabel":"Account"},
    name: "customer",
    children: [{
      path: "my-profile",
      component: _14eb416e,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile"
    }, {
      path: "addresses-details",
      component: _217d351c,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "addresses-details/create",
      component: _7291b084,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2032e0dc,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "order-history",
      component: _7ea96db2,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "order-history/:orderId",
      component: _153ae201,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }, {
      path: "company-information",
      component: _6ba8b2b7,
      meta: {"titleLabel":"Company Information"},
      name: "customer-company-information"
    }]
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _55dcbf48,
    name: "attributeCollection"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _287f3c0f,
    name: "attributeCollectionItem"
  }, {
    path: "/faq.html",
    component: _131e89aa,
    name: "faq"
  }, {
    path: "/aktuelt/:slug+.html",
    component: _7fdb607d,
    name: "blogPost"
  }, {
    path: "/aktuelt.html",
    component: _7d1a0e3d,
    name: "blog"
  }, {
    path: "/search",
    component: _1f49f93d,
    name: "search"
  }, {
    path: "/:slug+",
    component: _73914e12,
    name: "page"
  }, {
    path: "/",
    component: _76024347,
    name: "home"
  }, {
    path: "/customer/MyAccount/MyAccount",
    component: _4fce01bb,
    name: "customer-MyAccount-MyAccount"
  }, {
    path: "/customer/MyAccount/useSidebarLinkGroups",
    component: _500702e8,
    name: "customer-MyAccount-useSidebarLinkGroups"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
